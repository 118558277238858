import { manifest, version } from '@parcel/service-worker';
// install
async function install() {
    const cache = await caches.open(version);
    await cache.addAll(manifest);
}
addEventListener('install', (e) => e.waitUntil(install()));
// active
async function activate() {
    const keys = await caches.keys();
    await Promise.all(keys.map((key) => key !== version && caches.delete(key)));
}
addEventListener('activate', (e) => e.waitUntil(activate()));

// When there's an incoming fetch request, try and respond with a precached resource, otherwise fall back to the network
addEventListener('fetch', (event) => {
    console.log('Fetch intercepted for:', event.request.url);
    event.respondWith(
        caches.match(event.request).then((cachedResponse) => {
            if (cachedResponse) {
                return cachedResponse;
            }
            return fetch(event.request);
        })
    );
});
